<template>
    <div>
        <div class="row">
            <div class="col-md-6 col-sm-12">
                <div class="form-group">
                    <label for="kodeKohort">No. Register Kohort</label>
                    <input type="text" id="kodeKohort" class="form-control" v-model="form.kodeKohort" />
                </div>
            </div>
            <div class="col-md-6 col-sm-12">
                <div class="form-group">
                    <label for="tglRegister">Tgl Register</label>
                    <Datepicker :format="dateFormat" id="tglRegister" locale="id" v-model="form.tglRegister"
                        cancelText="Batal" selectText="Pilih" />
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6 col-sm-12">
                <div class="form-group">
                    <label for="wilayahPosyandu">Wilayah Posyandu</label>
                    <Select2 id="wilayahPosyandu" placeholder="Pilih Posyandu"
                        v-model="form.wilayahPosyandu" :options="options.wilayahPosyandu" />
                </div>
            </div>
            <div class="col-md-6 col-sm-12">
                <div class="form-group">
                    <label for="Simpus">Simpus</label>
                    <input type="text" id="Simpus" class="form-control" v-model="form.simpus" />
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6 col-sm-12">
                <div class="form-group">
                    <label for="NIK">NIK</label>
                    <input type="text" id="NIK" class="form-control" v-model="form.nik" />
                </div>
            </div>
            <div class="col-md-6 col-sm-12">
                <div class="form-group">
                    <label for="KK">No. KK</label>
                    <input type="text" id="KK" class="form-control" v-model="form.noKK" />
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 col-sm-12">
                <div class="form-group">
                    <label for="noBPJS">No. Kartu/BPJS</label>
                    <input type="text" id="noBPJS" class="form-control" v-model="form.noBPJS" />
                </div>
            </div>
        </div>
        <hr />
        <div class="row justify-content-between">
            <div class="col-md-12 col-sm-12 text-right">
                <button @click.prevent="$emit('next', $event, 'DataIbuHamil', form)" class="btn btn-custom waves-effect waves-light m-b-5">
                    <span>Data Ibu Hamil</span> 
                    <i class="mdi mdi-arrow-right-bold-outline m-l-5"></i>
                </button>
            </div>
        </div>
    </div>
</template>
<script>
import { useDasar } from "@/api/dasar";
import Select2 from "vue3-select2-component";
export default {
    name:'DataRegistrasi',
    components:{
        Select2,
    },
    data:() => {
        return {
            dateFormat: "d/M/Y",
            storageName:'DataRegistrasi',
            options: {
                wilayahPosyandu: [],
            },
            form: {
                kodeKohort: "",
                tglRegister: new Date(),
                wilayahPosyandu: "all",
                simpus: "",
                nik: "",
                noKK: "",
                noBPJS: "",
            }
        }
    },
    methods:{
        checkLocalStorage() {
            let data = localStorage.getItem(this.storageName)
            if (typeof data !== 'undefined' && data != null) {
                this.form = JSON.parse(data)
            }
        }
    },
    async mounted() {
        const apiDataDasar                 = useDasar()
              this.options.wilayahPosyandu = await apiDataDasar.getPosyandu();
                this.checkLocalStorage()
    }
}
</script>