import axios from 'axios';
import { defineStore } from 'pinia';

export const useDasar = defineStore('dasar-store', () => {
    const cache = {};

    const fetchData = async (endpoint) => {
        try {
            const response = await axios.get(`dasar/${endpoint}`);
            return response.data.content;
        } catch (error) {
            console.error(`Error fetching ${endpoint} data:`, error);
            throw error;
        }
    };

    const getCachedData = async (endpoint) => {
        if (cache[endpoint]) {
            return cache[endpoint];
        } else {
            const data = await fetchData(endpoint);
            cache[endpoint] = data;
            return data;
        }
    };

    const mapToUpperCase = (data) => {
        return data.map(item => ({
            id: item.Kode_Posyandu || item.kode || item.Kode || item.KodeKab || item.Kode_Dusun,
            text: item.Posyandu || item.jenis || item.Pekerjaan || item.Agama || item.Pendidikan || item.golongandarah || item.Desa || item.Dusun
        }));
    };

    return {
        async getPosyandu(kdDesa = '') {
            const endpoint = (kdDesa !== '' && typeof kdDesa !== 'undefined') ? `posyandu?kdDesa=${kdDesa}` : 'posyandu';
            const posyandu = await getCachedData(endpoint);
            return mapToUpperCase(posyandu.posyandu);
        },
    
        async getJenisGakin() {
            const jenisGakin = await getCachedData('jenis-gakin');
            return mapToUpperCase(jenisGakin.gakin);
        },
    
        async getPekerjaan() {
            const pekerjaan = await getCachedData('pekerjaan');
            return mapToUpperCase(pekerjaan.pekerjaan);
        },
    
        async getAgama() {
            const agama = await getCachedData('agama');
            return mapToUpperCase(agama.agama);
        },
    
        async getPendidikan() {
            const pendidikan = await getCachedData('pendidikan');
            return mapToUpperCase(pendidikan.pendidikan);
        },
    
        async getGolonganDarah() {
            const golonganDarah = await getCachedData('golongan-darah');
            return mapToUpperCase(golonganDarah.golongandarah);
        },
    
        async getDesa() {
            const desa = await getCachedData('desa');
            return mapToUpperCase(desa.desa);
        },
    
        async getDusun(kodeDesa) {
            const dusun = await getCachedData(`dusun/${kodeDesa}`);
            return mapToUpperCase(dusun.dusun);
        }
    };
});
