<template>
    <div>
        <div class="row">
            <div class="col-md-12 col-sm-12">
                <div class="form-group">
                    <label for="alamat">Alamat</label>
                    <input type="text" id="alamat" placeholder="Masukan Alamat Lengkap" class="form-control" v-model="form.alamat" />
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6 col-sm-6">
                <div class="form-group">
                    <label for="rt">RT</label>
                    <input type="text" id="rt" placeholder="Masukan RT" class="form-control"
                        v-model="form.rt" />
                </div>
            </div>
            <div class="col-md-6 col-sm-6">
                <div class="form-group">
                    <label for="rw">RW</label>
                    <input type="text" id="rw" placeholder="Masukan RW" class="form-control"
                        v-model="form.rw" />
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 col-sm-12">
                <div class="form-group">
                    <label for="desa">Desa</label>
                    <Select2 id="desa" placeholder="Pilih Desa" v-model="form.desa" :options="options.desa"
                        @select="selectDusun($event)" />
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 col-sm-12">
                <div class="form-group">
                    <label for="dusun">Dusun</label>
                    <Select2 id="dusun" placeholder="Pilih Dusun" v-model="form.dusun"
                        :options="options.dusun" />
                </div>
            </div>
        </div>
        <hr />
        <div class="row justify-content-between">
            <div class="col-md-6 col-sm-12">
                <button @click.prevent="$emit('prev', $event, 'DataSuami', form)" class="btn btn-custom waves-effect waves-light m-b-5">
                    <i class="mdi mdi-arrow-left-bold-outline m-r-5"></i>
                    <span>Data Suami</span>
                </button>
            </div>
            <div class="col-md-6 col-sm-12 text-right">
                <button @click.prevent="$emit('next', $event, 'KehamilanSekarang', form)" class="btn btn-custom waves-effect waves-light m-b-5">
                    <span>Kehamilan Sekarang</span> 
                    <i class="mdi mdi-arrow-right-bold-outline m-l-5"></i>
                </button>
            </div>
        </div>
    </div>
</template>
<script>
import Select2 from "vue3-select2-component";
import { useDasar } from "@/api/dasar";
export default {
    name:'DataAlamat',
    components:{
        Select2,
    },
    data:() => {
        return {
            api:null,
            options:{
                desa:[],
                dusun:[]
            },
            form:{
                desa:'',
                dusun:'',
                alamat:'',
                rt:'',
                rw:''
            }
        }
    },
    methods:{
        async selectDusun($event) {
            if ($event.id !== '') {
                this.options.dusun = await this.api.getDusun($event.id)
            }
        },
        async checkLocalStorage() {
            const data = localStorage.getItem('DataAlamat');
            if (data) {
                const parsedData = JSON.parse(data);
                const { desa } = parsedData;
                if (desa) {
                    await this.selectDusun({ id: desa });
                }
                this.form = parsedData;
            }
        }
    },
    async mounted() {
        this.api = useDasar()
        this.options.desa = await this.api.getDesa()
        this.checkLocalStorage()
    }
}
</script>