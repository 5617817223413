<template>
    <div>
        <div class="row">
            <div class="col-md-12 col-sm-12">
                <div class="form-group">
                    <label for="namaSuami">Nama Suami</label>
                    <input type="text" id="namaSuami" placeholder="Masukan Nama Lengkap Suami" class="form-control" v-model="form.namaSuami" />
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6 col-sm-12">
                <div class="form-group">
                    <label for="pekerjaanSuami">Pekerjaan Suami</label>
                    <Select2 id="pekerjaanSuami" placeholder="Pilih Pekerjaan Suami"
                        v-model="form.pekerjaanSuami" :options="options.perkerjaan" />
                </div>
            </div>
            <div class="col-md-4 col-sm-12">
                <div class="form-group">
                    <label for="tglLahirSuami">Tgl Lahir</label>
                    <Datepicker :format="dateFormat" id="tglLahirSuami" locale="id"
                        v-model="form.tglLahirSuami" cancelText="Batal" selectText="Pilih" />
                </div>
            </div>
            <div class="col-md-2 col-sm-12">
                <div class="form-group">
                    <label for="umurTahunSuami">Tahun</label>
                    <input type="text" disabled id="umurTahunSuami" class="form-control"
                        v-model="form.umurTahunSuami" />
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6 col-sm-12">
                <div class="form-group">
                    <label for="agamaSuami">Agama</label>
                    <Select2 id="agamaSuami" placeholder="Pilih Agama" v-model="form.agamaSuami"
                        :options="options.agama" />
                </div>
            </div>
            <div class="col-md-6 col-sm-12">
                <div class="form-group">
                    <label for="pendidikanSuami">Pendidikan</label>
                    <Select2 id="pendidikanSuami" placeholder="Pilih Pendidikan"
                        v-model="form.pendidikanSuami" :options="options.pendidikan" />
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 col-sm-12">
                <div class="form-group">
                    <label for="noTelponSuami">No. Telp/HP</label>
                    <input type="text" id="noTelponSuami" placeholder="Masukan Nomor Telepon Suami" class="form-control"
                        v-model="form.noTelponSuami" />
                </div>
            </div>
        </div>
        <hr />
        <div class="row justify-content-between">
            <div class="col-md-6 col-sm-12">
                <button @click.prevent="$emit('prev', $event, 'DataIbuHamil', form)" class="btn btn-custom waves-effect waves-light m-b-5">
                    <i class="mdi mdi-arrow-left-bold-outline m-r-5"></i>
                    <span>Data Ibu Hamil</span>
                </button>
            </div>
            <div class="col-md-6 col-sm-12 text-right">
                <button @click.prevent="$emit('next', $event, 'DataAlamat', form)" class="btn btn-custom waves-effect waves-light m-b-5">
                    <span>Data alamat</span> 
                    <i class="mdi mdi-arrow-right-bold-outline m-l-5"></i>
                </button>
            </div>
        </div>
    </div>
</template>
<script>
import Select2 from "vue3-select2-component";
import { calculateAge } from "@/helpers/functions"
import { useDasar } from "@/api/dasar";
export default {
    name:'DataSuami',
    components:{
        Select2,
    },
    data:() => {
        return {
            dateFormat: "d/M/Y",
            options: {
                perkerjaan: [],
                jenisGakin: [],
                agama: [],
                pendidikan: [],
                golDarah: []
            },
            form: {
                namaSuami: "",
                pekerjaanSuami: "",
                tglLahirSuami: new Date(),
                umurTahunSuami: 0,
                agamaSuami: '',
                pendidikanSuami: '',
                noTelponSuami: '',
            },
        }
    },
    methods:{
        hitungUmur() {
            let umur = calculateAge(this.form.tglLahirSuami)
            this.form.umurTahunSuami = umur.umurTahun
        },
        checkLocalStorage() {
            let data = localStorage.getItem('DataSuami')
            if (typeof data !== 'undefined' && data != null) {
                this.form = JSON.parse(data)
            }
        }
    },
    watch: {
        "form.tglLahirSuami": {
            handler: "hitungUmur",
            immediate: true,
        }
    },
    async mounted() {
        const apiDataDasar                 = useDasar()
              this.options.perkerjaan      = await apiDataDasar.getPekerjaan();
              this.options.agama           = await apiDataDasar.getAgama();
              this.options.pendidikan      = await apiDataDasar.getPendidikan();
              this.checkLocalStorage()
    },
}
</script>