<template>
    <div>
        <div class="row">
            <div class="col-md-11 col-sm-12">
                <div class="form-group">
                    <label for="namaIbu">Nama Ibu</label>
                    <input type="text" placeholder="Masukan Nama Lengkap Ibu Hamil" id="namaIbu" class="form-control" v-model="form.namaIbu" />
                </div>
            </div>
            <div class="col-md-1 col-sm-12 mt-4">
                <div class="form-group mt-3">
                    <div class="checkbox checkbox-custom">
                        <input v-model="form.gakin" id="gakin" type="checkbox" />
                        <label for="gakin"> Gakin </label>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6 col-sm-12">
                <div class="form-group">
                    <label for="perkerjaan">Pekerjaan</label>
                    <Select2 id="perkerjaan" placeholder="Pilih Perkerjaan" v-model="form.perkerjaan"
                        :options="options.perkerjaan" />
                </div>
            </div>
            <div class="col-md-6 col-sm-12">
                <div class="form-group">
                    <label for="jenisGakin">Jenis</label>
                    <Select2 id="jenisGakin" placeholder="Pilih Jenis" v-model="form.jenisGakin"
                        :options="options.jenisGakin" />
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6 col-sm-12">
                <div class="form-group">
                    <label for="tglLahir">Tgl Lahir</label>
                    <Datepicker :format="dateFormat" id="tglLahir" locale="id" v-model="form.tglLahir"
                        cancelText="Batal" selectText="Pilih" />
                </div>
            </div>
            <div class="col-md-2 col-sm-12">
                <div class="form-group">
                    <label for="umurTahun">Tahun</label>
                    <input type="text" disabled id="umurTahun" class="form-control"
                        v-model="form.umurTahun" />
                </div>
            </div>
            <div class="col-md-2 col-sm-12">
                <div class="form-group">
                    <label for="umurBulan">Bulan</label>
                    <input type="text" disabled id="umurBulan" class="form-control"
                        v-model="form.umurBulan" />
                </div>
            </div>
            <div class="col-md-2 col-sm-12">
                <div class="form-group">
                    <label for="umurHari">Hari</label>
                    <input type="text" disabled id="umurHari" class="form-control"
                        v-model="form.umurHari" />
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-3 col-sm-12">
                <div class="form-group">
                    <label for="agama">Agama</label>
                    <Select2 id="agama" placeholder="Pilih Agama" v-model="form.agama"
                        :options="options.agama" />
                </div>
            </div>
            <div class="col-md-3 col-sm-12">
                <div class="form-group">
                    <label for="pendidikan">Pendidikan</label>
                    <Select2 id="pendidikan" placeholder="Pilih Pendidikan" v-model="form.pendidikan"
                        :options="options.pendidikan" />
                </div>
            </div>
            <div class="col-md-3 col-sm-12">
                <div class="form-group">
                    <label for="nomorTelepon">No. Telepon/HP</label>
                    <input type="text" id="nomorTelepon" placeholder="08xxxxxxxxxx" class="form-control" v-model="form.nomorTelepon" />
                </div>
            </div>
            <div class="col-md-3 col-sm-12">
                <div class="form-group">
                    <label for="pendidikan">Pendidikan</label>
                    <label for="golDarah">Gol Darah</label>
                    <Select2 id="golDarah" placeholder="Pilih Gol Darah" v-model="form.golDarah"
                        :options="options.golDarah" />
                </div>
            </div>
        </div>
        <hr />
        <div class="row justify-content-between">
            <div class="col-md-6 col-sm-12">
                <button @click.prevent="$emit('prev', $event, 'DataRegistrasi', form)" class="btn btn-custom waves-effect waves-light m-b-5">
                    <i class="mdi mdi-arrow-left-bold-outline m-r-5"></i>
                    <span>Data Registrasi</span>
                </button>
            </div>
            <div class="col-md-6 col-sm-12 text-right">
                <button @click.prevent="$emit('next', $event, 'DataSuami', form)" class="btn btn-custom waves-effect waves-light m-b-5">
                    <span>Data Suami</span> 
                    <i class="mdi mdi-arrow-right-bold-outline m-l-5"></i>
                </button>
            </div>
        </div>
    </div>
</template>
<script>
import Select2 from "vue3-select2-component";
import { calculateAge } from "@/helpers/functions"
import { useDasar } from "@/api/dasar";

export default {
    name:'DataIbuHamil',
    components:{
        Select2,
    },
    data:() => {
        return {
            dateFormat: "d/M/Y",
            storageName:'DataIbuHamil',
            options: {
                perkerjaan: [],
                jenisGakin: [],
                agama: [],
                pendidikan: [],
                golDarah: []
            },
            form: {
                namaIbu: "",
                gakin: false,
                perkerjaan: "",
                jenisGakin: "",
                tglLahir: new Date(),
                umurTahun: 0,
                umurBulan: 0,
                umurHari: 0,
                agama: '',
                nomorTelepon: '',
                pendidikan: '',
            },
        }
    },
    methods:{
        hitungUmur() {
            let umur = calculateAge(this.form.tglLahir)
            this.form.umurTahun = umur.umurTahun
            this.form.umurBulan = umur.umurBulan
            this.form.umurHari = umur.umurHari
        },
        checkLocalStorage() {
            let data = localStorage.getItem(this.storageName)
            if (typeof data !== 'undefined' && data != null) {
                this.form = JSON.parse(data)
            }
        }
    },
    watch: {
        "form.tglLahir": {
            handler: "hitungUmur",
            immediate: true,
        }
    },
    async mounted() {
        const apiDataDasar                 = useDasar()
              this.options.jenisGakin      = await apiDataDasar.getJenisGakin();
              this.options.perkerjaan      = await apiDataDasar.getPekerjaan();
              this.options.agama           = await apiDataDasar.getAgama();
              this.options.pendidikan      = await apiDataDasar.getPendidikan();
              this.options.golDarah        = await apiDataDasar.getGolonganDarah();
              this.checkLocalStorage()
    },
}
</script>