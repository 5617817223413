<template>
    <div>
        <h4 class="header-title m-t-0 m-b-20">1. Fungsi Reproduksi</h4>
        <div class="row">
            <div class="col-md-4 col-sm-12">
                <div class="form-group">
                    <label for="hpht">HPHT</label>
                    <Datepicker :format="dateFormat" id="hpht" locale="id" placeholder="Tanggal HPHT"
                                        v-model="form.hpht" cancelText="Batal" selectText="Pilih" @update="handleHPHT" />
                </div>
            </div>
            <div class="col-md-4 col-sm-12">
                <div class="form-group">
                    <label for="htpHpl">HTP/HPL</label>
                    <Datepicker :format="dateFormat" id="htpHpl" locale="id" placeholder="Tanggal HTP/HPL"
                                        v-model="form.htpHpl" cancelText="Batal" selectText="Pilih" />
                </div>
            </div>
            <div class="col-md-4 col-sm-12">
                <div class="form-group">
                    <label for="bbAwal">BB Awal</label>
                    <div class="input-group">
                        <input type="text" id="bbAwal" v-model="form.bbAwal" class="form-control">
                        <div class="input-group-append">
                            <span class="input-group-text">kg</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mb-4">
            <div class="col-md-3 col-sm-12">
                <label for="lila">LILA</label>
                <div class="input-group">
                    <input type="text" id="lila" v-model="form.lila" class="form-control">
                    <div class="input-group-append">
                        <span class="input-group-text">cm</span>
                    </div>
                </div>
            </div>
            <div class="col-md-3 col-sm-12">
                <label for="tinggi">Tinggi</label>
                <div class="input-group">
                    <input type="text" id="tinggi" v-model="form.tinggi" class="form-control">
                    <div class="input-group-append">
                        <span class="input-group-text">cm</span>
                    </div>
                </div>
            </div>
            <div class="col-md-3 col-sm-12">
                <label for="berat">Berat</label>
                <div class="input-group">
                    <input type="text" id="berat" v-model="form.berat" class="form-control">
                    <div class="input-group-append">
                        <span class="input-group-text">kg</span>
                    </div>
                </div>
            </div>
            <div class="col-md-3 col-sm-12">
                <label for="hb">HB</label>
                <div class="input-group">
                    <input type="text" id="hb" v-model="form.hb" class="form-control">
                    <div class="input-group-append">
                        <span class="input-group-text">gr%</span>
                    </div>
                </div>
            </div>
        </div>
        <h4 class="header-title m-t-0 m-b-20">2. Kehamilan Saat Register</h4>
        <div class="row">
            <div class="col-md-4 col-sm-12">
                <div class="input-group">
                    <input type="text" id="umurKehamilan" v-model="form.umurKehamilan" class="form-control">
                    <div class="input-group-append">
                        <span class="input-group-text">Minggu</span>
                    </div>
                </div>
            </div>
            <div class="col-md-2 col-sm-12">
                <div class="form-group mt-2">
                    <div class="checkbox checkbox-custom">
                        <input v-model="form.k1Murni" id="k1Murni" type="checkbox" />
                        <label for="k1Murni"> K1 Murni </label>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 col-sm-12">
                <label for="keluhanAksesPertama">Keluhan Akses Pertama</label>
                <textarea class="form-control" id="keluhanAksesPertama" v-model="form.keluhanAksesPertama" rows="2"></textarea>
            </div>
        </div>
        <h4 class="header-title m-t-4 m-b-20">3. Riwayat Obsterti</h4>
        <div class="row">
            <div class="col-md-4 col-sm-12">
                <div class="form-group">
                    <label for="gravida">Gravida</label>
                    <input type="text" id="gravida" class="form-control" v-model="form.gravida" />
                </div>
            </div>
            <div class="col-md-4 col-sm-12">
                <div class="form-group">
                    <label for="partus">Partus</label>
                    <input type="text" id="partus" class="form-control" v-model="form.partus" />
                </div>
            </div>
            <div class="col-md-4 col-sm-12">
                <div class="form-group">
                    <label for="abortus">Abortus</label>
                    <input type="text" id="abortus" class="form-control" v-model="form.abortus" />
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6 col-sm-12">
                <div class="form-group">
                    <label for="jumlahAnakHidup">Jumlah Anak Hidup</label>
                    <input type="number" id="jumlahAnakHidup" class="form-control" v-model="form.jumlahAnakHidup" />
                </div>
            </div>
            <div class="col-md-6 col-sm-12">
                <div class="form-group">
                    <label for="jumlahAnakLahirKurangBulan">Jumlah Anak Lahir Kurang Bulan</label>
                    <input type="number" id="jumlahAnakLahirKurangBulan" class="form-control" v-model="form.jumlahAnakLahirKurangBulan" />
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6 col-sm-12">
                <div class="form-group">
                    <label for="jumlahLahirMati">Jumlah Lahir Mati</label>
                    <input type="number" id="jumlahLahirMati" class="form-control" v-model="form.jumlahLahirMati" />
                </div>
            </div>
            <div class="col-md-6 col-sm-12">
                <div class="form-group">
                    <label for="jarakPersalinanTerakhir">Jarak Persalinan Terakhir</label>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="input-group">
                                <input type="number" id="jarakPersalinanTerakhirTahun" v-model="jarakPersalinanTerakhirTahun" class="form-control">
                                <div class="input-group-append">
                                    <span class="input-group-text">Tahun</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="input-group">
                                <input type="number" id="jarakPersalinanTerakhirBulan" v-model="jarakPersalinanTerakhirBulan" class="form-control">
                                <div class="input-group-append">
                                    <span class="input-group-text">Bulan</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6 col-sm-12">
                <div class="form-group">
                    <label for="penolongPersalinanTerakhir">Penolong Persalinan Terakhir</label>
                    <Select2 id="penolongPersalinanTerakhir" placeholder="Pilih Penolong Persalinan Terakhir" v-model="form.penolongPersalinanTerakhir"
                        :options="options.penolongPersalinanTerakhir" />
                </div>
            </div>
            <div class="col-md-6 col-sm-12">
                <div class="form-group">
                    <label for="kontraSebsiSebelumKehamilan">Kontrasebsi Sebelum Kehamilan</label>
                    <Select2 id="kontraSebsiSebelumKehamilan" placeholder="Pilih Kontrasebsi Sebelum Kehamilan" v-model="form.kontraSebsiSebelumKehamilan"
                        :options="options.kontraSebsiSebelumKehamilan" />
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6 col-sm-12">
                <div class="form-group">
                    <label for="caraPersalinanYangLalu">Cara Persalinan Yang Lalu</label>
                    <Select2 id="caraPersalinanYangLalu" placeholder="Pilih Cara Persalinan Yang Lalu" v-model="form.caraPersalinanYangLalu"
                        :options="options.caraPersalinanYangLalu" />
                </div>
            </div>
            <div class="col-md-6 col-sm-12">
                <div class="form-group">
                    <label for="tanggalKonselingLaktasi">Tanggal Konseling Laktasi</label>
                    <Datepicker :format="dateFormat" id="tanggalKonselingLaktasi" locale="id" placeholder="Tanggal Konseling Laktasi"
                                        v-model="form.tanggalKonselingLaktasi" cancelText="Batal" selectText="Pilih" />
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-3 col-sm-12">
                <h4 class="header-title">4. Imunisasi TT Calong Pengantin</h4>
            </div>
            <div class="col-md-1 col-sm-12 mt-2" v-for="item in tt" :key="item.name">
                <div class="form-group ml-3">
                    <div class="checkbox checkbox-custom">
                        <input v-model="form[item.name]" :id="item.name" type="checkbox" />
                        <label :for="item.name"> {{ item.label }} </label>
                    </div>
                </div>
            </div>
        </div>
        <h4 class="header-title mt-0 m-b-20">5. Riwayat Penyakit Kronis</h4>
        <div class="row">
            <div class="col-md-2 col-sm-6" v-for="item in riwayatPenyakitKronis" :key="item.name">
                <div class="form-group ml-3">
                    <div class="checkbox checkbox-custom">
                        <input v-model="form[item.name]" :id="item.name" type="checkbox" />
                        <label :for="item.name"> {{ item.label }} </label>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Select2 from "vue3-select2-component";
export default {
    name: "KehamilanSekarang",
    components: {
        Select2
    },
    data: () => {
        return {
            dateFormat: "d/M/Y",
            options:{
                penolongPersalinanTerakhir:[],
                kontraSebsiSebelumKehamilan:[],
                caraPersalinanYangLalu:[],
            },
            tt:[{
                    name:'tt1',
                    label:'TT1'
                },
                {
                    name:'tt2',
                    label:'TT2'
                },
                {
                    name:'tt3',
                    label:'TT3'
            }],
            riwayatPenyakitKronis:[
                {
                    name:'jantung',
                    label:'Jantung'
                },
                {
                    name:'paruParu',
                    label:'Paru-paru'
                },
                {
                    name:'diabetes',
                    label:'Diabetes'
                },
                {
                    name:'ginjal',
                    label:'Ginjal'
                },
                {
                    name:'hati',
                    label:'Hati'
                },
                {
                    name:'anemia',
                    label:'Anemia'
                },
                {
                    name:'malaria',
                    label:'Malaria'
                },
                {
                    name:'asma',
                    label:'Asma'
                },
                {
                    name:'tbc',
                    label:'TBC'
                },
                {
                    name:'epilepsi',
                    label:'Epilepsi'
                },
                {
                    name:'jiwa',
                    label:'Jiwa'
                },
                {
                    name:'pms',
                    label:'PMS'
                },
            ],
            form:{
                hpht:'',
                htpHpl:'',
                bbAwal:'',
                umurKehamilan:'',
                k1Murni:false,
                keluhanAksesPertama:'',
                gravida:'',
                partus:'',
                abortus:'',
                jumlahAnakHidup:'',
                jumlahAnakLahirKurangBulan:'',
                jumlahLahirMati:'',
                jarakPersalinanTerakhirTahun:'',
                jarakPersalinanTerakhirBulan:'',
                penolongPersalinanTerakhir:'',
                kontraSebsiSebelumKehamilan:'',
                caraPersalinanYangLalu:'',
                tanggalKonselingLaktasi:'',
                tt1:false,
                tt2:false,
                tt3:false,
            }
        }
    },
    methods:{
        handleHPHT(modelData) {
            console.log(modelData)
        }
    }
}
</script>