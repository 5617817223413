<template>
    <div>
        <!-- Page-Title -->
        <div class="row">
            <div class="col-sm-12">
                <div class="page-title-box">
                    <h2 class="page-title">Register Ibu Hamil</h2>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="card-box">
                    <ul class="nav nav-tabs" role="tablist">
                        <li class="nav-item" v-for="tab in tabs" :key="tab.id">
                            <a class="nav-link"
                                :class="(activeComponent == tab.component) ? 'active' : ''"
                                @click.prevent="setComponent($event,tab.component)" 
                                :id="tab.id" 
                                data-toggle="tab" 
                                role="tab" :aria-controls="tab.ariaControls" 
                                :href="`#${tab.ariaControls}`"
                                :aria-selected="tab.ariaSelected">
                                <span class="fs-1">{{ tab.title }}</span>
                            </a>
                        </li>
                    </ul>
                    <div class="tab-content">
                        <div class="tab-pane show active" role="tabpanel" aria-labelledby="home-b2-tab">
                            <component 
                                :is="activeComponent" 
                                @next="setComponent" 
                                @prev="setComponent">
                            </component>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>
.tabs-bordered .nav-item .nav-link {
    padding: 10px 10px !important;
}
</style>
<script>
import { useToast } from "vue-toastification";
import {
    DataRegistrasi, 
    DataIbuHamil, 
    DataSuami, 
    DataAlamat, 
    KehamilanSekarang,
    StatusRisti,
    P4k,
    DataStatusBumil,
    RiwayatPersalinanPerkawinan
} from "./register-component"

export default {
    name: "RegisterBumil",
    components: {
        DataRegistrasi,
        DataIbuHamil,
        DataSuami,
        DataAlamat,
        KehamilanSekarang,
        StatusRisti,
        P4k,
        DataStatusBumil,
        RiwayatPersalinanPerkawinan
    },
    setup() {
        const toast = useToast();
        return {
            toast,
        };
    },
    data: () => {
        return {
            activeComponent:'DataRegistrasi',
            form:{},
            tabs: [
                {
                    id: 'data-registrasi',
                    ariaControls: 'data-registrasi-content',
                    ariaSelected: true,
                    title: 'Data Registrasi',
                    component: 'DataRegistrasi'
                },
                {
                    id: 'data-ibu-hamil',
                    ariaControls: 'data-ibu-hamil-content',
                    ariaSelected: true,
                    title: 'Data Ibu Hamil',
                    component: 'DataIbuHamil'
                },
                {
                    id: 'data-suami',
                    ariaControls: 'data-suami-content',
                    ariaSelected: true,
                    title: 'Data Suami',
                    component: 'DataSuami'
                },
                {
                    id: 'data-alamat',
                    ariaControls: 'data-alamat-content',
                    ariaSelected: true,
                    title: 'Data Alamat',
                    component: 'DataAlamat'
                },
                {
                    id: 'kehamilan-sekarang',
                    ariaControls: 'kehamilan-sekarang-content',
                    ariaSelected: true,
                    title: 'Kehamilan Sekarang',
                    component: 'KehamilanSekarang'
                },
                {
                    id: 'status-risti',
                    ariaControls: 'status-risti-content',
                    ariaSelected: true,
                    title: 'Status Risti',
                    component: 'StatusRisti'
                },
                {
                    id: 'p-4-k',
                    ariaControls: 'p-4-k-content',
                    ariaSelected: true,
                    title: 'P 4 K',
                    component: 'P4k'
                },
                {
                    id: 'data-status-bumil',
                    ariaControls: 'data-status-bumil-content',
                    ariaSelected: true,
                    title: 'Data Status Bumil',
                    component: 'DataStatusBumil'
                },
                {
                    id: 'riwayat-persalinan-perkawainan',
                    ariaControls: 'riwayat-persalinan-perkawainan-content',
                    ariaSelected: true,
                    title: 'Riwayat Persalinan/Perkawinan',
                    component: 'RiwayatPersalinanPerkawinan'
                },
                {
                    id: 'simpan',
                    ariaControls: 'simpan-content',
                    ariaSelected: true,
                    title: 'Simpan',
                    component: 'Simpan'
                },
            ],
        };
    },
    methods: {
        setComponent($event, component, formChild) {
            if ($event.isTrusted) {
                if (typeof formChild !== 'undefined') {
                    localStorage.setItem(this.activeComponent, JSON.stringify(formChild))
                }
                this.activeComponent = component
            }
        },
        calculateAgeSuami() {
            const birthDate = new Date(this.form.tglLahirSuami);
            const today = new Date();

            let age = today.getFullYear() - birthDate.getFullYear();
            const monthDiff = today.getMonth() - birthDate.getMonth();
            const dayDiff = today.getDate() - birthDate.getDate();

            if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
                age--;
            }

            this.form.umurTahunSuami = age;
        },
    }
};
</script>
