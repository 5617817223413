const calculateAge = (tanggalLahir) => {
    const birthDate = new Date(tanggalLahir);
    const today = new Date();

    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    const dayDiff = today.getDate() - birthDate.getDate();

    if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
        age--;
    }

    let umurTahun = age;
    let umurBulan = monthDiff < 0 ? 12 + monthDiff : monthDiff;
    let umurHari  =
        dayDiff < 0
            ? birthDateDaysInMonth(
                  birthDate.getMonth(),
                  birthDate.getFullYear()
              ) + dayDiff
            : dayDiff;
    return {
        umurTahun,
        umurBulan,
        umurHari
    }
};

const birthDateDaysInMonth = (month, year) => {
    return new Date(year, month + 1, 0).getDate()
}

export { calculateAge, birthDateDaysInMonth };
